var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type == 'edit' || (_vm.type == 'preview' && _vm.totalCount > 0))?_c('div',{ref:"tooth-position",staticClass:"tooth-position"},[_c('div',{staticClass:"tooth-top"},[_c('div',{staticClass:"tooth-left"},[(_vm.type == 'preview')?_c('div',{staticClass:"preview-text"},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(_vm.foreignTrade2(_vm.top_left).join(".")))]):_c('span',[_vm._v(_vm._s(_vm.top_left.join("")))])]):_vm._l((8),function(n){return _c('div',{key:n,staticClass:"tooth-base",class:{
            select: _vm.top_left.indexOf(String(9 - n)) !== -1,
            isDisabled: _vm.selectableTooth(9 - n, 'top_left'),
          },attrs:{"data-position":"top_left","data-num":9 - n}},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(n))]):_c('span',[_vm._v(_vm._s(9 - n))])])})],2),_c('div',{staticClass:"tooth-right"},[(_vm.type == 'preview')?_c('div',{staticClass:"preview-text"},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(_vm.foreignTrade1(_vm.top_right, 8).join(".")))]):_c('span',[_vm._v(_vm._s(_vm.top_right.join("")))])]):_vm._l((8),function(n){return _c('div',{key:n,staticClass:"tooth-base",class:{
            select: _vm.top_right.indexOf(String(n)) !== -1,
            isDisabled: _vm.selectableTooth(n, 'top_right'),
          },attrs:{"data-position":"top_right","data-num":n}},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(n + 8))]):_c('span',[_vm._v(_vm._s(n))])])})],2)]),_c('div',{staticClass:"tooth-bottom"},[_c('div',{staticClass:"tooth-left"},[(_vm.type == 'preview')?_c('div',{staticClass:"preview-text"},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(_vm.foreignTrade1(_vm.bottom_left, 24).join(".")))]):_c('span',[_vm._v(_vm._s(_vm.bottom_left.join("")))])]):_vm._l((8),function(n){return _c('div',{key:n,staticClass:"tooth-base",class:{
            select: _vm.bottom_left.indexOf(String(9 - n)) !== -1,
            isDisabled: _vm.selectableTooth(9 - n, 'bottom_left'),
          },attrs:{"data-position":"bottom_left","data-num":9 - n}},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(9 - n + 24))]):_c('span',[_vm._v(_vm._s(9 - n))])])})],2),_c('div',{staticClass:"tooth-right"},[(_vm.type == 'preview')?_c('div',{staticClass:"preview-text"},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(_vm.foreignTrade2(_vm.bottom_right, true).join(".")))]):_c('span',[_vm._v(_vm._s(_vm.bottom_right.join("")))])]):_vm._l((8),function(n){return _c('div',{key:n,staticClass:"tooth-base",class:{
            select: _vm.bottom_right.indexOf(String(n)) !== -1,
            isDisabled: _vm.selectableTooth(n, 'bottom_right'),
          },attrs:{"data-position":"bottom_right","data-num":n},on:{"":function($event){}}},[(_vm.toothStates == 3)?_c('span',[_vm._v(_vm._s(9 - n + 16))]):_c('span',[_vm._v(_vm._s(n))])])})],2)]),(_vm.type == 'edit')?_c('div',{ref:"rectangle",staticClass:"rectangle"}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }