<template>
  <div
    ref="tooth-position"
    class="tooth-position"
    v-if="type == 'edit' || (type == 'preview' && totalCount > 0)"
  >
    <div class="tooth-top">
      <div class="tooth-left">
        <div class="preview-text" v-if="type == 'preview'">
          <span v-if="toothStates == 3">{{
            foreignTrade2(top_left).join(".")
          }}</span>
          <span v-else>{{ top_left.join("") }}</span>
        </div>
        <template v-else>
          <div
            class="tooth-base"
            :class="{
              select: top_left.indexOf(String(9 - n)) !== -1,
              isDisabled: selectableTooth(9 - n, 'top_left'),
            }"
            v-for="n in 8"
            data-position="top_left"
            :data-num="9 - n"
            :key="n"
          >
            <!-- @click="selectItem(9 - n, 'top_left')" -->
            <span v-if="toothStates == 3">{{ n }}</span>
            <span v-else>{{ 9 - n }}</span>
          </div>
        </template>
      </div>
      <div class="tooth-right">
        <div class="preview-text" v-if="type == 'preview'">
          <span v-if="toothStates == 3">{{
            foreignTrade1(top_right, 8).join(".")
          }}</span>
          <span v-else>{{ top_right.join("") }}</span>
        </div>
        <template v-else>
          <div
            class="tooth-base"
            :class="{
              select: top_right.indexOf(String(n)) !== -1,
              isDisabled: selectableTooth(n, 'top_right'),
            }"
            v-for="n in 8"
            :key="n"
            data-position="top_right"
            :data-num="n"
          >
            <!-- @click="selectItem(n, 'top_right')" -->
            <span v-if="toothStates == 3">{{ n + 8 }}</span>
            <span v-else>{{ n }}</span>
          </div>
        </template>
      </div>
    </div>
    <div class="tooth-bottom">
      <div class="tooth-left">
        <div class="preview-text" v-if="type == 'preview'">
          <span v-if="toothStates == 3">{{
            foreignTrade1(bottom_left, 24).join(".")
          }}</span>
          <span v-else>{{ bottom_left.join("") }}</span>
        </div>
        <template v-else>
          <div
            class="tooth-base"
            :class="{
              select: bottom_left.indexOf(String(9 - n)) !== -1,
              isDisabled: selectableTooth(9 - n, 'bottom_left'),
            }"
            v-for="n in 8"
            data-position="bottom_left"
            :data-num="9 - n"
            :key="n"
          >
            <!-- @click="selectItem(9 - n, 'bottom_left')" -->
            <span v-if="toothStates == 3">{{ 9 - n + 24 }}</span>
            <span v-else>{{ 9 - n }}</span>
          </div>
        </template>
      </div>
      <div class="tooth-right">
        <div class="preview-text" v-if="type == 'preview'">
          <span v-if="toothStates == 3">{{
            foreignTrade2(bottom_right, true).join(".")
          }}</span>
          <span v-else>{{ bottom_right.join("") }}</span>
        </div>
        <template v-else>
          <div
            class="tooth-base"
            :class="{
              select: bottom_right.indexOf(String(n)) !== -1,
              isDisabled: selectableTooth(n, 'bottom_right'),
            }"
            v-for="n in 8"
            data-position="bottom_right"
            :data-num="n"
            :key="n"
            @
          >
            <!-- click="selectItem(n, 'bottom_right')" -->
            <span v-if="toothStates == 3">{{ 9 - n + 16 }}</span>
            <span v-else>{{ n }}</span>
          </div>
        </template>
      </div>
    </div>

    <div v-if="type == 'edit'" ref="rectangle" class="rectangle"></div>
  </div>
</template>
<script>
import DragSelect from "dragselect";
export default {
  props: {
    type: {
      type: String,
      default: "edit", // 可选 preview
    },
    tooth_top_left: {
      type: String,
      default: () => {
        return "";
      },
    },
    tooth_top_right: {
      type: String,
      default: () => {
        return "";
      },
    },
    tooth_bottom_left: {
      type: String,
      default: () => {
        return "";
      },
    },
    tooth_bottom_right: {
      type: String,
      default: () => {
        return "";
      },
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    selectable_tooth_top_left: {
      type: String,
      default: () => {
        return "";
      },
    },
    selectable_tooth_top_right: {
      type: String,
      default: () => {
        return "";
      },
    },
    selectable_tooth_bottom_left: {
      type: String,
      default: () => {
        return "";
      },
    },
    selectable_tooth_bottom_right: {
      type: String,
      default: () => {
        return "";
      },
    },
    toothStates: [String, Number],
  },
  data() {
    return {
      top_left: [],
      top_right: [],
      bottom_left: [],
      bottom_right: [],
      // dsObject: null,
    };
  },
  computed: {
    totalCount() {
      let top_left_len = this.top_left.length;
      let top_right_len = this.top_right.length;
      let bottom_left_len = this.bottom_left.length;
      let bottom_right_len = this.bottom_right.length;
      return top_left_len + top_right_len + bottom_left_len + bottom_right_len;
    },
  },
  mounted() {
    this.loadToothData();
    if (this.type == "edit") {
      this.$nextTick(() => {
        this.initDragSelect();
      });
    }
  },
  methods: {
    foreignTrade1(arr, index) {
      let arrery = arr.map((item) => {
        return Number(item) + index;
      });
      return arrery;
    },
    foreignTrade2(arr, type) {
      let arrery = arr.map((item) => {
        if (item == 1) {
          item = 8;
        } else if (item == 2) {
          item = 7;
        } else if (item == 3) {
          item = 6;
        } else if (item == 4) {
          item = 5;
        } else if (item == 5) {
          item = 4;
        } else if (item == 6) {
          item = 3;
        } else if (item == 7) {
          item = 2;
        } else if (item == 8) {
          item = 1;
        }
        if (type) {
          if (item == 1) {
            item = 17;
          } else if (item == 2) {
            item = 18;
          } else if (item == 3) {
            item = 19;
          } else if (item == 4) {
            item = 20;
          } else if (item == 5) {
            item = 21;
          } else if (item == 6) {
            item = 22;
          } else if (item == 7) {
            item = 23;
          } else if (item == 8) {
            item = 24;
          }
        }
        return item;
      });
      return arrery;
    },
    initDragSelect() {
      var ds = new DragSelect({
        selectables:
          this.$refs["tooth-position"].querySelectorAll(".tooth-base"), // 可被选中的元素节点
        selector: this.$refs["rectangle"], // 可手动添加一个拖动选择框（译者：可用于修改拖动选择框的样式，需要手动添加 css 的 position:absolute 样式）
        area: this.$refs["tooth-position"], // 拖动生效的区域，如果未提供，它将是整个网页文档
        customStyles: false, // 如果设置为true，则默认情况下拖动选择框将不应用任何样式（绝对位置除外）
        // multiSelectKeys: ["ctrlKey", "shiftKey", "metaKey"], // 允许多选的特殊键，默认为 ctrl，shift，meta(Mac系统的专有键)
        multiSelectMode: true, // 如果设置为true，则默认情况下将启用多选行为，而无需使用多选键，默认值：false
        // autoScrollSpeed: 0, // 选择时区域滚动的速度（如果有），单位是像素，设置为0以禁用自动滚动，默认值：1
        // onDragStart: function (element) {}, // 用户点击该区域时触发此回调，在DragSelect函数代码运行之后执行，用于设置事件侦听器
        // onDragMove: function (element) {}, // 用户拖动该区域时触发此回调，在DragSelect函数代码运行之后执行，用于设置事件侦听器
        // onElementSelect: (element) => {
        //   console.log('onElementSelect')
        //   let data = element.dataset;
        //   this.selectItem(data.num, data.position);
        //   // ds.clearSelection();
        // }, // 每次选择元素时触发的回调函数
        // onElementUnselect: (element) => {
        //   console.log('onElementUnselect')
        //   let data = element.dataset;
        //   this.selectItem(data.num, data.position);
        //   // ds.clearSelection();
        // }, // 每次取消选择元素时触发的回调函数
        callback: (elements) => {
          for (let i = 0; i < elements.length; i++) {
            let data = elements[i].dataset;
            this.selectItem(data.num, data.position);
          }
          ds.clearSelection();
        }, // 用户释放鼠标后触发，返回所有被选定的元素节点
      });
      // this.dsObject = ds;
      // this.dsObject.start();
    },
    loadToothData() {
      this.top_left = this.tooth_top_left.split("") || [];

      this.top_right = this.tooth_top_right.split("") || [];
      this.bottom_left = this.tooth_bottom_left.split("") || [];
      this.bottom_right = this.tooth_bottom_right.split("") || [];
    },
    selectItem(n, key) {
      if (this.selectableTooth(n, key)) {
        return false;
      }
      let list = Object.assign([], this[key]);
      let idx = list.findIndex((i) => i == n);
      if (idx !== -1) {
        list.splice(idx, 1);
      } else {
        list.push(n);
      }

      if (["top_right", "bottom_right"].includes(key)) {
        // 正序
        list.sort((a, b) => a * 1 - b * 1);
      } else if (["top_left", "bottom_left"].includes(key)) {
        // 倒序
        list.sort((a, b) => b * 1 - a * 1);
      }
      this[key] = list;

      this.$emit("update:tooth_top_left", this.top_left.join(""));
      this.$emit("update:tooth_top_right", this.top_right.join(""));
      this.$emit("update:tooth_bottom_left", this.bottom_left.join(""));
      this.$emit("update:tooth_bottom_right", this.bottom_right.join(""));

      this.$emit("change", {
        tooth_top_left: this.top_left.join(""),
        tooth_top_right: this.top_right.join(""),
        tooth_bottom_left: this.bottom_left.join(""),
        tooth_bottom_right: this.bottom_right.join(""),
      });
    },
    selectableTooth(value, location) {
      if (!this.isDisabled) return;
      if (location == "top_left") {
        let top_left = this.selectable_tooth_top_left.split("") || [];
        return !top_left.includes(value + "");
      } else if (location == "top_right") {
        let top_right = this.selectable_tooth_top_right.split("") || [];
        return !top_right.includes(value + "");
      } else if (location == "bottom_left") {
        let bottom_left = this.selectable_tooth_bottom_left.split("") || [];
        return !bottom_left.includes(value + "");
      } else if (location == "bottom_right") {
        let bottom_right = this.selectable_tooth_bottom_right.split("") || [];
        return !bottom_right.includes(value + "");
      } else {
        return false;
      }
    },
  },
  watch: {
    tooth_top_left: {
      deep: true,
      handler() {
        this.loadToothData();
      },
    },
    tooth_top_right: {
      deep: true,
      handler() {
        this.loadToothData();
      },
    },
    tooth_bottom_left: {
      deep: true,
      handler() {
        this.loadToothData();
      },
    },
    tooth_bottom_right: {
      deep: true,
      handler() {
        this.loadToothData();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.tooth-position {
  display: flex;
  flex-direction: column;
  user-select: none;
  .tooth-top {
    display: flex;
    border-bottom: 1px solid #000;
    flex: 1;
  }
  .tooth-bottom {
    display: flex;
    flex: 1;
  }
  .tooth-left {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    text-align: right;
  }
  .tooth-right {
    flex: 1;
    border-left: 1px solid #000;
    display: flex;
    justify-content: flex-start;
    padding: 5px;
    text-align: left;
  }

  .preview-text {
    height: 15px;
    line-height: 15px;
    white-space: nowrap;
  }

  .tooth-base {
    width: 25px;
    height: 25px;
    line-height: 25px;
    border: 2px solid #ddd;
    text-align: center;
    border-radius: 50%;
    flex-shrink: 0;
    margin: 2px;
    font-size: 12px;
    cursor: pointer;
    &.select {
      border-color: #009ae3;
      color: #009ae3;
    }
    &.isDisabled {
      background-color: #edf2fc;
      border-color: #dcdfe6;
      color: #c0c4cc;
      cursor: not-allowed;
    }
  }
}
</style>
<style lang="scss">
.rectangle {
  background-color: rgba(0, 154, 227, 0.1);
  border: 1px solid #009ae3;
  position: absolute;
}

</style>